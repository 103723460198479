import moment from 'moment';

const namespaced = true

const state = {
    activeSessions: [],
    joinedChannel: null,
    joinedConferenceChannel: null
}

const actions = {
    async getAllSessions({dispatch}) {
        return await dispatch('database/getItems', {table: 'sessions'}, {root: true})
    },
    async getSingleSession({dispatch}, id) {
        //return await dispatch('database/getItem', {table: 'sessions', id: id}, {root: true})
        let data = await dispatch('database/getMultiple', ['sessions', 'locations', 'session_location_links',], {root: true})
        let session = data['sessions'].find(s => s.id == id);
        //location-block
        let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id);

        let location = null;
        sessloc_link.forEach(sessloc => {
            if (sessloc && sessloc.location_id) {
                location = data['locations'].filter(location => location.id == sessloc.location_id)[0];
            }
        })
        session.location = location;
        return session;
    },

    async getSessionType({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['sessiontypes', 'session_sessiontype_links'], {root: true});
        let typelink = data['session_sessiontype_links'].find(ssl => ssl.session_id == id);
        let type = null;
        if (typelink) {
            type = data['sessiontypes'].find(st => st.id == typelink.sessiontype_id);
        }
        return type;

    },

    async getSessionListLive({dispatch}) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        //let data = await super.getMultiple(['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links']);
        let sessions = [];
        for (let i = 0; i < data['sessions'].length; i++) {
            //TODO:filter for parent_id == null
            let session = data['sessions'][i];
            if (!session.parent_id) {
                let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
                let type = null;

                if (sesstype_link && sesstype_link.sessiontype_id) {
                    type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
                }
                session.session_type_text = type ? type.name : '';
                session.session_type_order = type ? type.order : 0;
                session.is_scientific = type ? type.scientific : true;
                //location-block
                let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
                let location = null;
                if (sessloc_link && sessloc_link.location_id) {

                    location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
                }
                session.location = location;

                session.location_order = location ? location.order : 0;
                //if (session.is_scientific) {
                    session.chair = []
                    session.speakers = [];
                    let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                    if (sess_pres_links.length > 0) {
                        sess_pres_links.forEach(sess_pres_link => {
                            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                            sessionroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.chair.push(person);
                                    }
                                }
                            })
                            presroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.speakers.push(person);
                                    }
                                }
                            })
                        })
                    } else {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                    }

                    sessions.push(session);
                //}
            }
        }
        return sessions;
    },

    async getSessionListForDay({dispatch}, day) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        //let data = await super.getMultiple(['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links']);
        let sessions = [];
        for (let i = 0; i < data['sessions'].length; i++) {
            let session = data['sessions'][i];
            if (session.start_time.indexOf(day) != -1) {
                //sesstype-block
                let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
                let type = null;

                if (sesstype_link && sesstype_link.sessiontype_id) {
                    type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
                }
                session.session_type_text = type ? type.name : '';
                session.session_type_order = type ? type.order : 0;
                session.is_scientific = type ? type.scientific : true;
                //location-block
                let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
                let location = null;
                if (sessloc_link && sessloc_link.location_id) {

                    location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
                }
                session.location = location;

                session.location_order = location ? location.order : 0;
                if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                    session.chair = []
                    session.speakers = [];
                    let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                    if (sess_pres_links.length > 0) {
                        sess_pres_links.forEach(sess_pres_link => {
                            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                            sessionroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.chair.push(person);
                                    }
                                }
                            })
                            presroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.speakers.push(person);
                                    }
                                }
                            })
                        })
                    } else {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                    }

                    sessions.push(session);
                }
            }

        }
        return sessions;
    },
    async getSessionListInCollection({dispatch}, collection_id) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        //let data = await super.getMultiple(['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links']);
        let sessions = [];
        for (let i = 0; i < data['sessions'].length; i++) {
            let session = data['sessions'][i];
            if (session.parent_id == collection_id) {
                //sesstype-block
                let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
                let type = null;

                if (sesstype_link && sesstype_link.sessiontype_id) {
                    type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
                }
                session.session_type_text = type ? type.name : '';
                session.session_type_order = type ? type.order : 0;
                session.is_scientific = type ? type.scientific : true;
                //location-block
                let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
                let location = null;
                if (sessloc_link && sessloc_link.location_id) {

                    location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
                }
                session.location = location;

                session.location_order = location ? location.order : 0;
                if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                    session.chair = []
                    session.speakers = [];
                    let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                    if (sess_pres_links.length > 0) {
                        sess_pres_links.forEach(sess_pres_link => {
                            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                            sessionroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.chair.push(person);
                                    }
                                }
                            })
                            presroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.speakers.push(person);
                                    }
                                }
                            })
                        })
                    } else {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                    }

                    sessions.push(session);
                }
            }

        }
        return sessions;
    },

    async getSessionFavorite({dispatch}, id) {
        let session = await dispatch('database/getItem', {table: 'sessions', id: id}, {root: true})
        let data = await dispatch('database/getMultiple', ['sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        //sesstype-block
        let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
        let type = null;

        if (sesstype_link && sesstype_link.sessiontype_id) {
            type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
        }
        session.session_type_text = type ? type.name : '';
        session.session_type_order = type ? type.order : 0;
        session.is_scientific = type ? type.scientific : true;
        //location-block
        let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
        let location = null;
        if (sessloc_link && sessloc_link.location_id) {

            location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
        }
        session.location = location;

        session.location_order = location ? location.order : 0;
        if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
            session.chair = []
            session.speakers = [];
            let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
            if (sess_pres_links.length > 0) {
                sess_pres_links.forEach(sess_pres_link => {
                    let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                    let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                    sessionroles.forEach(role => {
                        let person = data['persons'].filter(person => person.id == role.person_id)[0];
                        if (person) {
                            person.function = role.function;
                            if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                session.chair.push(person);
                            }
                        }
                    })
                    presroles.forEach(role => {
                        let person = data['persons'].filter(person => person.id == role.person_id)[0];
                        if (person) {
                            person.function = role.function;
                            if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                session.speakers.push(person);
                            }
                        }
                    })
                })
            } else {
                let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                sessionroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.chair.push(person);
                        }
                    }
                })
            }

        }
        return session;
    },
    async getSessionFavoriteForList({dispatch}, ids) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        //sesstype-block
        let sessions = data['sessions'].filter(sess => ids.indexOf(sess.id) != -1);
        console.log('All fav sessions', sessions)
        let sessionsToReturn = [];
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
            let type = null;

            if (sesstype_link && sesstype_link.sessiontype_id) {
                type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
            }
            session.session_type_text = type ? type.name : '';
            session.session_type_order = type ? type.order : 0;
            session.is_scientific = type ? type.scientific : true;
            //location-block
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = null;
            if (sessloc_link && sessloc_link.location_id) {

                location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            }
            session.location = location;

            session.location_order = location ? location.order : 0;
            if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                session.chair = []
                session.speakers = [];
                let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                if (sess_pres_links.length > 0) {
                    sess_pres_links.forEach(sess_pres_link => {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                        presroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.speakers.push(person);
                                }
                            }
                        })
                    })
                } else {
                    let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                    sessionroles.forEach(role => {
                        let person = data['persons'].filter(person => person.id == role.person_id)[0];
                        if (person) {
                            person.function = role.function;
                            if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                session.chair.push(person);
                            }
                        }
                    })
                }

            }
            sessionsToReturn.push(session)
        }

        return sessionsToReturn;
    },


    async getSessionPersons({dispatch}, id) {
        let persons = [];
        let data = await dispatch('database/getMultiple', ['person_roles', 'persons'], {root: true});
        for (let i = 0; i < data['person_roles'].length; i++) {
            let role = data['person_roles'][i];
            if (role.session_id == id) {
                for (let j = 0; j < data['persons'].length; j++) {
                    let person = data['persons'][j];
                    if (role.person_id == person.id) {
                        person.order = role.order;
                        person.function = role.function;
                        persons.push(person);
                    }
                }
            }
        }
        return persons;
    },
    async getSpeakersInSession({dispatch}, id) {
        let speakers = [];
        let data = await dispatch('database/getMultiple', ['session_presentation_links', 'person_roles', 'persons'], {root: true});

        for (let i = 0; i < data['session_presentation_links'].length; i++) {
            let sess_pres_link = data['session_presentation_links'][i];
            if (sess_pres_link.session_id === id) {
                for (let k = 0; k < data['person_roles'].length; k++) {
                    let role = data['person_roles'][k];
                    if (role.presentation_id == sess_pres_link.presentation_id) {
                        for (let l = 0; l < data['persons'].length; l++) {
                            let person = data['persons'][l];
                            if (person.id == role.person_id) {
                                person.function = role.function;
                                if (JSON.stringify(speakers).indexOf(person.id) == -1) {
                                    speakers.push(person);
                                }
                            }
                        }
                    }
                }
            }
        }
        return speakers;
    },
    async getPresentationList({dispatch}, id) {
        let presentations = [];
        let data = await dispatch('database/getMultiple', ['session_presentation_links', 'presentations', 'person_roles', 'persons'], {root: true});

        for (let i = 0; i < data['session_presentation_links'].length; i++) {
            let sess_pres_link = data['session_presentation_links'][i];
            if (sess_pres_link.session_id === id) {
                for (let j = 0; j < data['presentations'].length; j++) {
                    let presentation = data['presentations'][j];
                    if (sess_pres_link.presentation_id == presentation.id) {
                        sess_pres_link['color'] = '#fff';
                        sess_pres_link['speakers'] = sess_pres_link['speakers'] || [];
                        sess_pres_link['title'] = presentation.title;
                        sess_pres_link['pres_id'] = presentation.id;
                        sess_pres_link['outline'] = presentation.outline;
                        sess_pres_link['video_link'] = presentation.video_link;
                        sess_pres_link['slides_id'] = presentation.slides_id;
                        sess_pres_link['file_id'] = presentation.file_id;
                        sess_pres_link['file_name'] = presentation.file_name;
                        sess_pres_link['poster_file_id'] = presentation.poster_file_id;
                        sess_pres_link['poster_file_name'] = presentation.poster_file_name;
                        sess_pres_link['sess_id'] = id;

                        //person-block
                        for (let k = 0; k < data['person_roles'].length; k++) {
                            let role = data['person_roles'][k];
                            if (role.presentation_id == presentation.id) {
                                for (let l = 0; l < data['persons'].length; l++) {
                                    let person = data['persons'][l];
                                    if (person.id == role.person_id) {
                                        person.function = role.function;
                                        if (JSON.stringify(sess_pres_link['speakers']).indexOf(person.id) == -1) {
                                            sess_pres_link['speakers'].push(person);
                                        }
                                    }
                                }
                            }
                        }

                        presentations.push(sess_pres_link);
                    }
                }
            }

        }
        return presentations.sort((a, b) => a.order - b.order);
    },

    async getDays({dispatch}) {
        let data = await dispatch('database/getMultiple', ['sessions'], {root: true});

        let sessions = data['sessions'].sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
        });
        let days = {};
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            if (session.start_time) {
                let start = session.start_time.split('T')[0];
                if (!days[start]) {
                    days[start] = {};
                    days[start].label = moment(start).format('ddd, MMM D')
                }

            }
        }
        console.log("data")
        console.log(data)
        return days;

    },


    async getGridData({dispatch}, start) {
        let data = await dispatch('database/getMultiple', ['sessions', 'locations', 'session_location_links', 'sessiontypes', 'session_sessiontype_links'], {root: true});
        let locations = data['locations'];
        let location_links = data['session_location_links'];
        let sessions = data['sessions'];
        let sessiontypes = data['sessiontypes'];
        let session_sessiontype_links = data['session_sessiontype_links'];
        let breaks = [];
        let rooms = [];
        let dayStart = 23; //HARDCODED?
        let dayEnd = 0;
        for (let i = 0; i < locations.length; i++) {
            let location = locations[i];
            if (location.name != 'Media library') {
                let room = {id: location.id, name: location.name, sessions: [], slots: {}, order: location.order};
                for (let j = 0; j < location_links.length; j++) {
                    let link = location_links[j];
                    if (link.location_id == location.id) {
                        for (let k = 0; k < sessions.length; k++) {
                            let session = sessions[k];
                            if (link.session_id == session.id) {
                                if (!session.parent_id) {
                                    if (session.start_time.indexOf(start) != -1) {
                                        for (let l = 0; l < session_sessiontype_links.length; l++) {
                                            let sessTypeLink = session_sessiontype_links[l];
                                            if (sessTypeLink.session_id == session.id) {
                                                for (let m = 0; m < sessiontypes.length; m++) {
                                                    let sessType = sessiontypes[m];
                                                    if (sessTypeLink.sessiontype_id == sessType.id) {
                                                        session.session_type_text = sessType.name;
                                                        session.is_scientific = sessType.scientific
                                                    }
                                                }
                                            }
                                        }

                                        let startTmp = session.start_time.split('T')[1];
                                        startTmp = parseInt(startTmp.split(':')[0])
                                        let endTmp = session.end_time.split('T')[1];
                                        endTmp = parseInt(endTmp.split(':')[0])
                                        let timeStart = startTmp
                                        let timeEnd = endTmp
                                        if (timeStart < dayStart) dayStart = timeStart;
                                        if (timeEnd > dayEnd) dayEnd = timeEnd;
                                        if (session.recurring > 0) {
                                            breaks.push(session);
                                        } else {
                                            room.sessions.push(session);
                                            room.slots[session.start_time + '/' + session.end_time] = room.slots[session.start_time + '/' + session.end_time] || {};
                                            room.slots[session.start_time + '/' + session.end_time]['sessions'] = room.slots[session.start_time + '/' + session.end_time]['sessions'] || []
                                            if (JSON.stringify(room.slots[session.start_time + '/' + session.end_time]['sessions']).indexOf(session.id) == -1) {
                                                room.slots[session.start_time + '/' + session.end_time]['sessions'].push(session);
                                            }

                                        }
                                    }
                                }

                            }
                        }
                    }
                }
                if (room.sessions.length) {
                    rooms.push(room);
                }
            }

        }
        console.log('BREAK', breaks)
        console.log('rooms', rooms)
        return {rooms: rooms, breaks: breaks, dayStart: dayStart, dayEnd: dayEnd};
    },


    async getSessionsForLocationInSlot({dispatch}, payload) {
        let _location = payload.location;
        let start = payload.start;
        let end = payload.end;
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        let sessions = [];
        for (let i = 0; i < data['sessions'].length; i++) {
            let session = data['sessions'][i];
            //sesstype-block
            let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
            let type = null;

            if (sesstype_link && sesstype_link.sessiontype_id) {
                type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
            }
            session.session_type_text = type ? type.name : '';
            session.session_type_order = type ? type.order : 0;
            session.is_scientific = type ? type.scientific : true;
            //location-block
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = null;
            if (sessloc_link && sessloc_link.location_id) {

                location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            }
            session.location = location;

            session.location_order = location ? location.order : 0;
            if (session.location && session.location.id == _location && session.start_time === start && session.end_time === end) {
                if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                    session.chair = []
                    session.speakers = [];
                    let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                    if (sess_pres_links.length > 0) {
                        sess_pres_links.forEach(sess_pres_link => {
                            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                            sessionroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.chair.push(person);
                                    }
                                }
                            })
                            presroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.speakers.push(person);
                                    }
                                }
                            })
                        })
                    } else {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                    }


                    sessions.push(session);
                }
            }


        }
        return sessions;
    },
    async getSessionsForLocation({dispatch}, _location) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true})
        let sessions = [];
        for (let i = 0; i < data['sessions'].length; i++) {
            let session = data['sessions'][i];
            //sesstype-block
            let sesstype_link = data['session_sessiontype_links'].filter(typelink => typelink.session_id == session.id)[0];
            let type = null;

            if (sesstype_link && sesstype_link.sessiontype_id) {
                type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];
            }
            session.session_type_text = type ? type.name : '';
            session.session_type_order = type ? type.order : 0;
            session.is_scientific = type ? type.scientific : true;
            //location-block
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = null;
            if (sessloc_link && sessloc_link.location_id) {

                location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            }
            session.location = location;

            session.location_order = location ? location.order : 0;
            if (session.location && session.location.id == _location) {
                if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                    session.chair = []
                    session.speakers = [];
                    let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                    if (sess_pres_links.length > 0) {
                        sess_pres_links.forEach(sess_pres_link => {
                            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                            sessionroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.chair.push(person);
                                    }
                                }
                            })
                            presroles.forEach(role => {
                                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                                if (person) {
                                    person.function = role.function;
                                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                        session.speakers.push(person);
                                    }
                                }
                            })
                        })
                    } else {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                    }


                    sessions.push(session);
                }
            }


        }
        return sessions;
    },
    async getLocation({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'locations', id: id}, {root: true})
    },

    async getStreamingRooms({dispatch}) {
        let locations = await dispatch('database/getItems', {table: 'locations'}, {root: true})
        let locationsToReturn = locations.filter(l => l.stream_link != null);
        return locationsToReturn
    },


}
const mutations = {
    setActiveSessions(state, data) {
        let active = [];
        data.forEach(entry => {
            active.push(entry.sessionId)
        })
        state.activeSessions = active;
    },
    setJoinedChannel: (state, {joinedChannel}) => {
        state.joinedChannel = joinedChannel
    },
    setJoinedConferenceChannel: (state, {joinedConferenceChannel}) => {
        state.joinedConferenceChannel = joinedConferenceChannel
    },
}
const getters = {}

export const sessions = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
